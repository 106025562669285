import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"
import GetProjects from "../components/getProjects"

const Projects = (props) => {
  return (

    <Layout>
      <Seo title="Projects" />
      <GetProjects />
      
    </Layout>
  )

}


export default Projects