import React from 'react';
import Card from './card'
import reactLogo from '../images/logos/react-logo.png'
import sinatraLogo from '../images/logos/sinatra-logo.png'
import rubyLogo from '../images/logos/ruby-logo.png'
import railsLogo from '../images/logos/rails-logo.png'
import javascriptLogo from '../images/logos/javascript-logo.png'

const GetProjects = (props) => {
  console.log(props)
  
  
  let project = props.projects.map((project, index) => (
    <Card key={index} {...project} {...props.profile} {...index} />
  ))
  
  return (
    <div className="container">
      <div className="row">
        {project}

      </div>
    </div>
  )
}


GetProjects.defaultProps = {
  profile: {
    author: 'Julian Rosenthal',
    profileUrl: 'https://github.com/JulianAR97'
  },
  projects: [
    {
      avatar: reactLogo,
      thumbnail: 'https://upload.wikimedia.org/wikipedia/commons/thumb/e/e0/Sudoku_Puzzle_by_L2G-20050714_standardized_layout.svg/250px-Sudoku_Puzzle_by_L2G-20050714_standardized_layout.svg.png',
      link: 'https://github.com/JulianAR97/sudoku',
      title: 'Sudoku',
      pubDate: new Date('2021-04-04')
    },
    {
      avatar: javascriptLogo,
      thumbnail: 'https://storage.googleapis.com/kx-live-ih/images/Trivia-min.2e16d0ba.fill-800x450.jpg',
      link: 'https://github.com/JulianAR97/trivia-frontend',
      title: 'Trivia',
      pubDate: new Date('2021-03-14')
    },
    {
      avatar: railsLogo,
      thumbnail: 'https://www.elegantthemes.com/blog/wp-content/uploads/2020/06/featured-job-board-plugins.png',
      link: 'https://github.com/JulianAR97/JobBoard',
      title: 'Job Board',
      pubDate: new Date('2021-02-07')
    },
    {
      avatar: sinatraLogo,
      thumbnail: 'https://i.pinimg.com/originals/e1/1d/8b/e11d8b8bad61135fcd4d52bab8ebd3f0.png',
      link: 'https://github.com/JulianAR97/gradebook',
      title: 'Gradebook',
      pubDate: new Date('2021-01-10')
    },
    {
      avatar: rubyLogo,
      thumbnail: 'https://www.pngkit.com/png/detail/229-2292685_stock-graph-png.png',
      link: 'https://github.com/JulianAR97/stock_lookup',
      title: 'Stock Lookup',
      pubDate: new Date('2020-12-06')
    }

  ]
}

export default GetProjects